import { convertToExportedView } from '@estee/elc-service';
import { VIEW_EMAIL_OPT_IN } from '@estee/elc-service-view-names';
import { IViewEmailOptInProps } from './ViewEmailOptIn';
import serviceNames from '~setup/CommunicationsService';

/*eslint no-restricted-syntax: ["off"]*/
export default convertToExportedView<IViewEmailOptInProps>(
    () => import('./ViewEmailOptIn'),
    VIEW_EMAIL_OPT_IN,
    serviceNames.viewEmailOptInViewController
);
