import { convertToExportedView } from '@estee/elc-service';
import { PRODUCT_NOTIFY_ME } from '@estee/elc-service-view-names';
import { IProductNotifyMePopUp } from './ProductNotifyMePopUp';
import serviceNames from '~setup/CommunicationsService';

/*eslint no-restricted-syntax: ["off"]*/
export default convertToExportedView<IProductNotifyMePopUp>(
    () => import('./ProductNotifyMePopUp'),
    PRODUCT_NOTIFY_ME,
    serviceNames.productNotifyMeViewController
);
