import { getModuleFederatedName, setupServiceInfo } from '@estee/elc-universal-utils';
import { ServiceManager } from '@estee/elc-service';
import {
    EMAIL_OPT_IN,
    HELPFUL_LINKS,
    PRODUCT_NOTIFY_ME,
    VIEW_EMAIL_OPT_IN
} from '@estee/elc-service-view-names';
import serviceNames, { CommunicationsService } from '~setup/CommunicationsService';
import { serviceBusBaseClass } from '~setup/ServiceBus';

import './exported/email-opt-in/index';
import './exported/helpful-links/index';
import './exported/product-notify-me/index';
import './exported/view-email-opt-in/index';

setupServiceInfo(__serviceInfo__);
const { name, version } = __serviceInfo__;

CommunicationsService.setViews(name, getModuleFederatedName(name), version, [
    EMAIL_OPT_IN,
    HELPFUL_LINKS,
    PRODUCT_NOTIFY_ME,
    VIEW_EMAIL_OPT_IN
]);

ServiceManager.setServiceStartParams({
    serviceName: name,
    diContainerImport: () => import('~setup/diContainer'),
    serviceKey: serviceNames.communicationsService,
    serviceBusControllerKey: serviceNames.serviceBusController,
    serviceBus: {
        serviceBusBaseClass
    }
});
