import { convertToExportedView } from '@estee/elc-service';
import { EMAIL_OPT_IN } from '@estee/elc-service-view-names';
import { IEmailOptInProps } from './EmailOptIn';
import serviceNames from '~setup/CommunicationsService';

/*eslint no-restricted-syntax: ["off"]*/
export default convertToExportedView<IEmailOptInProps>(
    () => import('./EmailOptIn'),
    EMAIL_OPT_IN,
    serviceNames.emailOptInViewController
);
