import { BaseService } from '@estee/elc-service';
import { ServiceInitializer } from '@estee/elc-universal-utils';

const { name, version } = __serviceInfo__;

export class CommunicationsService extends BaseService {
    constructor(serviceIntializer: ServiceInitializer) {
        super(name, version, serviceIntializer);
    }
}

export default {
    configStore: 'CONFIG_STORE',
    serviceBusController: 'SERVICE_BUS_CONTROLLER',
    pubSub: 'PUB_SUB_CLIENT',
    communicationsService: 'COMMUNICATIONS_SERVICE',
    communicationsApiSdk: 'COMMUNICATIONS_API_SDK',
    notifyMeFormValidator: 'NOTIFY_ME_FORM_VALIDATOR',
    httpTransport: 'HTTP_TRANSPORT',
    emailOptInViewController: 'EMAIL_OPT_IN_VIEW_CONTROLLER',
    emailOptInRepository: 'EMAIL_OPT_IN_REPOSITORY',
    productNotifyMeViewController: 'PRODUCT_NOTIFY_ME_VIEW_CONTROLLER',
    viewEmailOptInViewController: 'VIEW_EMAIL_OPT_IN_VIEW_CONTROLLER',
    productNotifyMeRepository: 'PRODUCT_NOTIFY_ME_REPOSITORY',
    notifyMeForm: 'NOTIFY_ME_FORM'
};
