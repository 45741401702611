import { ServiceManager, ServiceBusController } from '@estee/elc-service';
import { events, on, queryProvider, serviceQueries } from '@estee/elc-service-bus';
import { ServiceBusControllerBody } from '@estee/elc-service/typings/Service/ServiceManager/ServiceManager';

const queries = [[serviceQueries.EMAIL_OPT_IN, 'emailSignUp']];

const onEvents = [
    [events.GET_OPTIN_PROFILE_LOAD, 'emailOptInRequestFromAccount'],
    [events.AUTH_SUCCEDED, 'emailOptInRequest'],
    [events.APPOINTMENT_BOOKED, 'guestEmailOptInRequest'],
    [events.CONTINUE_AS_GUEST_CLICKED, 'guestEmailOptInRequest'],
    [events.USER_PROFILE_CHANGED, 'userProfileChanged'],
    [events.SIGNED_OUT, 'clearEmailOptIn'],
    [events.PRODUCT_NOTIFY_ME_REQUEST, 'onProductNotifyMeRequest'],
    [events.PRODUCT_NOTIFY_ME_REQUEST_FAILURE, 'onProductNotifyMeRequestFailure']
];

export class ServiceBus extends ServiceBusController {
    @on(events.ECOMM_STARTED, { replay: true })
    public onEcommStarted = () => {
        const id = setInterval(() => {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            if (serviceBusBaseClass) {
                clearInterval(id);
                queries.forEach(ServiceManager.setDecoratorOnFunction(queryProvider));
                onEvents.forEach(ServiceManager.setDecoratorOnFunction(on));
            }
        }, 50);
    };
}

// @ts-ignore
export const serviceBusBaseClass = new ServiceBus() as ServiceBusController &
    ServiceBusControllerBody;
